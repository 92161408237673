@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* COLORS */

.blue-regent {
  color: #265c91;
}

.okPaymentCustom{
  margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;
    text-align: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 50% !important;
}
.okPaymentFont{
  font-weight: bold !important;
  text-align: center !important;
}
.fw-bold{
  font-weight: bold !important;
  
}
.padding10px{
  padding-left: 10px;
}
.fw-bolder{
  font-weight: 600 !important;
}
/*** GENERIC CSS ***/
html,
body { 
}
.MuiFormControlLabel-label{
  width: 100% !important;
}
.cursorNone{
  cursor:not-allowed !important;
  pointer-events: none;
}
.flex-row {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}

.grid {
  display: grid;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.sticky {
  position: sticky;
  top: 20px;
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  font-size: 14px;
  color: #555555;
}

a {
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #005dba;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 54px;
  line-height: 1.5;
}

h2 {
  font-size: 48px;
  line-height: 1.4;
}

h3 {
  font-size: 35px;
  line-height: 1.5;
}

h4 {
  font-size: 27px;
  line-height: 1.5;
}

h5 {
  font-size: 24px;
  line-height: 1.5;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 14px;
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.section-title h2 {
  color: #003663;
  font-size: 37px;
  font-weight: 500;
}

.section-title h2 span {
  font-weight: 300;
}

.section-title h2 span.line {
  border-bottom: 1px solid #003663;
  font-weight: 500;
  display: inline-block;
}

.visible-xs {
  display: none;
}

.btn-default {
  background: #09b666;
  padding: 10px 35px;
  color: #fff;
  border-radius: 0px;
  min-width: 200px;
  text-transform: uppercase;
}

.btn-default:hover {
  color: #fff;
  background: #134db8;
}

.underlined {
  position: relative;
  display: inline-block;
}

.underlined::after {
  content: "";
  width: 100px;
  background: #134db8;
  height: 4px;
  position: absolute;
  top: 100%;
  left: 0%;
}

ul.list li {
  margin: 0px 0px 15px 0px;
}

/*** PRELOADER ***/
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 99999;
}

.preloader .lds-ripple {
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader .lds-ripple div {
  position: absolute;
  border: 2px solid #134db8;
  opacity: 1;
  border-radius: 0;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.preloader .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.img-rounded {
  border-radius: 15px;
}

.hover-animate {
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.hover-animate:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

/*** HEADER ***/
.topbar .tel {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
}

.dropdown.country {
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
}

.dropdown.country .dropdown-toggle:after {
  display: none;
}

.dropdown.country .dropdown-toggle {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  background: none;
  padding: 0;
  border: none;
  border-radius: 0px;
}

.dropdown.country .dropdown-toggle img {
  margin: 0px 3px;
}
/**/
.dropdown.normal-drop {
  display: inline-block;
  vertical-align: middle;
  border-radius: 0px;
  padding: 0px 10px;
  border-left: 1px solid #acacac;
}

.dropdown.normal-drop .dropdown-toggle:after {
  display: none;
}

.dropdown.normal-drop:first-child {
  border: none;
  border-right: 1px solid #acacac;
}

.dropdown.normal-drop .dropdown-toggle {
  color: #003663;
  font-weight: 500;
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
  background: none;
  padding: 0;
  border: none;
}

.dropdown.normal-drop .dropdown-toggle img {
  margin: 0px 3px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 15px;
}

.navbar-brand {
  margin-right: 88px;
}

.navbar-nav .nav-link {
  color: #fff;
  font-weight: 500;
}

.navbar-nav li {
  margin-right: 2px;
}

/*** BANNER ***/
.banner {
  background: url(../img/banner-home.png) 50% 50% no-repeat;
  background-size: cover;
  padding-top: 145px;
  min-height: 425px;
}

.banner .nav-tabs {
  background: #003663;
  border: none;
}

.banner .nav-tabs a {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 75px;
  display: inline-block;
  padding: 0px 40px;
  position: relative;
}

.banner .nav-tabs a img {
  margin-right: 10px;
}

.banner .nav-tabs a.active {
  background: #034c88;
  padding-top: 12px;
  margin-top: -12px;
}

.banner .nav-tabs a.active:after {
  content: "";
  background: url(../img/active-shadow.png) 50% 50% no-repeat;
  width: 10px;
  height: 73px;
  position: absolute;
  bottom: 0;
  right: -10px;
}

.banner .tab-pane {
  background: #034c88;
  padding: 5px 20px;
}

.banner .form-control {
  height: 73px;
  border: none;
  border-radius: 10px;
  padding-left: 65px;
  color: #003663;
  font-weight: 500;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
}

.banner .form-control::-webkit-input-placeholder {
  color: #003663;
}

.sprdiv {
  margin-left: -3px;
  margin-right: -3px;
  width: 100%;
}

.banner .sprdiv > div {
  padding: 0px 3px;
}

.banner .form-group {
  margin-bottom: 10px;
}

.banner .custom-control {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 5px;
  margin-bottom: 7px;
}

.banner .custom-control-label {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}

.banner .custom-control-label-react {
  color: #fff;
  font-weight: 800;
  font-size: 22px;
  vertical-align: middle;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  /* background-image: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"width="12"height="12"viewBox="-4 -4 8 8"%3e%3ccircler="3"fill="%23fff"/%3e%3c/svg%3e
  ); */
  background: #003663;
  border-radius: 50%;
  top: 8px;
  left: -20px;
  width: 8px;
  height: 8px;
}

.banner .btn-submit {
  background: #fbd316;
  color: #003663;
  font-weight: bold;
  font-size: 22px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  line-height: 73px;
  padding: 0px;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
}
.btn-submit {
  background: #fbd316;
  color: #003663;
  font-weight: bold;
  font-size: 22px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  /* line-height: 73px; */
  padding: 0px;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
}
.btn-submit img {
  margin-left: 10px;
}

.font-bold {
  font-weight: bold;
}
.flex-gap-8 {
  display: flex;
  gap: 8px;
}

.font-weight-700 {
  font-weight: 700;
}

.btn-select {
  background: #fbd316;
  color: #003663;
  font-size: 17px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0px;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
}

.banner .btn-submit img {
  margin-left: 10px;
}

.banner .form-control.flight1 {
  background: #fff url(../img/flight1.png) 10px 50% no-repeat;
}

.banner .form-control.flight2 {
  background: #fff url(../img/flight2.png) 10px 50% no-repeat;
}

.banner .form-control.bg-calendar {
  background: #fff url(../img/icon-calendar.png) 17px 50% no-repeat;
  padding-left: 60px;
}

.banner .form-control.bg-user {
  background: #fff url(../img/bg-user.png) 15px 50% no-repeat;
}

.banner .form-control.bg-desti {
  background: #fff url(../img/img-destination.png) 15px 50% no-repeat;
}

.banner .bot-minus {
  position: relative;
  bottom: -35px;
}

/*** FOOTER ***/
.footer {
  background: #003663;
  padding: 55px 0px;
}

.footer h3 {
  color: #ffffff;
  font-size: 21px;
  margin-bottom: 10px;
}

.footer p {
  color: #ffffff;
  font-size: 17px;
  line-height: 1.1;
}

.footer p a {
  color: #ffffff;
}

/*** Content ***/
.content {
  padding: 95px 0px;
  background: #eeeeee;
}

.default-yellow-btn {
  background: #fbd316;
  color: #003663;
  font-weight: 500;
  font-size: 13px;
  border-radius: 15px;
  display: inline-block;
  padding: 0px 40px;
  /* line-height: 38px; */
  text-align: center;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.24);
}

.default-yellow-btn .img-search {
  max-width: 20px;
  margin-left: 7px;
}

.default-yellow-btn.small {
  padding: 0px 20px;
  font-size: 17px;
}

.default-yellow-btn.tiny {
  padding: 0px 10px;
  font-size: 10px;
  line-height: 23px;
  min-width: 103px;
}

.default-white-btn {
  background: #fff;
  color: #003663;
  font-weight: 500;
  font-size: 17px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  line-height: 43px;
  text-align: center;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
}

.maplink {
  background: #fff;
  color: #003663;
  font-weight: 500;
  font-size: 21px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  line-height: 71px;
  text-align: center;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
}

.whitebox {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.4);
}

.whitebox {
}

.progress {
  height: 10px;
  border-radius: 0;
}

.progress-bar {
  background: #003663;
  border-radius: 0;
}

.smilemsg {
  padding: 60px 20px;
}

.emptybox {
  padding: 30px 20px 40px 20px;
}

.smilemsg h4 {
  color: #003663;
  font-size: 19px;
  font-weight: 600;
}

.smilemsg p {
  color: #003663;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.1;
}

.logobox {
  padding: 65px 20px;
}

.text-blue {
  color: #003663 !important;
}

.MaehZ {
  float: right;
}

.bJENwc {
  padding: 5px !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.m-width-175 {
  min-width: 175px;
}

.fs-19 {
  font-size: 19px;
}

.fs-13 {
  font-size: 13px;
}

.cust-space {
  padding: 40px 27px;
}

p.location {
  color: #003663;
  font-size: 13px;
  position: relative;
  padding-left: 27px;
}

p.location i {
  position: absolute;
  font-size: 30px;
  top: 0;
  left: 0;
}

.m-height {
  min-height: 450px;
}

p.mailp {
  color: #003663;
  font-size: 12px;
  position: relative;
  padding-top: 10px;
  text-align: left;
  padding-left: 54px;
}

p.mailp img {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
}

/*** Copyrights ***/
.copyrights {
  background: #003663;
  padding: 0px 0px 25px 0px;
}

.copyrights h5 {
  color: #ffffff;
  font-size: 17px;
  margin-top: 7px;
}

.copyrights p {
  color: #229fda;
  font-size: 13px;
  line-height: 1.1;
  border-top: 3px solid #229fda;
  margin: 0;
  padding: 15px 0px 0px 0px;
}

.filterbar .headi {
  color: #003663;
  font-size: 21px;
  font-weight: 500;
  margin-top: 20px;
}

.filterbar .custom-control {
  margin-bottom: 7px;
}

.filterbar .custom-control-input {
  width: 22px;
  height: 22px;
}
.carrier-label {
  color: #003663 !important;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  gap: 5px;
  display: flex;
  align-items: center;
}

.filterbar .custom-control-label,
.filterbar .custom-control-label .text-dark {
  color: #003663 !important;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}

.filterbar .custom-control-label::before {
  border: 2px solid #003663;
  width: 22px;
  height: 22px;
  top: 0;
  background: none;
}

.filterbar .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #003663;
  background: none;
}

.filterbar
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
    background:  50% 50% no-repeat; 
  background-image:    url(../img/64/Check-sign.png);
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;  
  border-radius: 0;
  top: 6px;
  left: -20px;
  width: 14px;
  height: 11px;
}

.backbtn {
  display: inline-block;
  background: #034c88;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  line-height: 46px;
  padding: 0px 25px;
}

.large-iframe {
  height: 1200px;
  border-top: none;
  border-left: 7px solid #fff;
  border-bottom: 20px solid #fff;
}

/*** Popular ***/
.popular {
  padding: 65px 0px 85px 0px;
}

.linksbar {
  border-bottom: 5px solid #003663;
  padding: 0px 0px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.leftlinks ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.leftlinks ul li {
  display: inline-block;
  list-style: none;
}

.leftlinks ul li:last-child a {
  border: none;
}

.leftlinks ul li a {
  color: #003663;
  font-size: 21px;
  border-right: 1px solid #003663;
  padding: 0px 20px;
}

.leftlinks ul li a:hover,
.leftlinks ul li a.active {
  font-weight: bold;
  cursor: pointer;
}

.rightdiv {
}

.package-text {
  display: inline-block;
  width: 100%;
  position: relative;
}

.stars {
  color: #ffc107;
}

.package-space .package-box {
  margin-bottom: 15px;
}

.package-box {
  display: inline-block;
  width: 100%;
  position: relative;
}

.package-box figure {
  float: left;
  width: 100%;
  height: 200px;
  margin: 0;
}

.package-box .pricetag {
  display: inline-block;
  color: #fff;
  font-size: 38px;
  font-weight: bold;
  position: absolute;
  right: 30px;
  bottom: 20px;
  line-height: 45px;
  border-bottom: 4px solid #fbd316;
}

.package-box .tag {
  display: inline-block;
  line-height: 33px;
  background: #fbd316;
  color: #003663;
  min-width: 130px;
  font-size: 22px;
  font-weight: 600;
  padding: 0 15px;
  position: absolute;
  top: 30px;
  left: -7px;
  border-bottom: 4px solid #032745;
}

.package-box .tag:before {
  content: "";
  background: url(../img/arrow-tag.png) top left no-repeat;
  width: 6px;
  height: 9px;
  position: absolute;
  bottom: -9px;
  left: 0px;
}

.package-text {
  margin-bottom: 60px;
}

.package-text p {
  color: #003663;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
}

.showborder {
  border-top: 4px solid #003663;
}

.show-more-btn {
  color: #003663;
  font-size: 17px;
  display: inline-block;
  margin-top: 20px;
}

.show-more-btn img {
  margin-left: 5px;
}
/*
.arrow-link a {
    display: inline-block;
    width: 100%;
    border: 2px solid #003663;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
    color: #003663;
    font-size: 17px;
    font-weight: 500;
    position: relative;
    line-height: 1.3;
    padding: 5px 10px 5px 20px;
    margin-bottom:10px;
}
*/
.arrow-link > div {
  display: inline-block;
  width: 100%;
  border: 2px solid #003663;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
  color: #003663;
  font-size: 17px;
  font-weight: 500;
  position: relative;
  line-height: 1.3;
  padding: 5px 10px 5px 20px;
  margin-bottom: 10px;
}

.arrow-link .bootstrap-select > .dropdown-toggle {
  position: relative;
  width: calc(100% + 30px);
  background: url(../img/arrow-down-black.png) 95% 50% no-repeat;
  border: none;
  font-size: 13px;
  font-weight: 400;
  color: #003663;
  min-width: 100%;
  outline: none;
  position: relative;
  left: -20px;
  padding-left: 20px;
}

.arrow-link .bootstrap-select > .dropdown-toggle:after {
  display: none;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #003663;
}

.arrow-link select {
  font-size: 13px;
  font-weight: 400;
  background: #fff url(../img/arrow-down-black.png) 100% 50% no-repeat;
  background-size: 15px auto;
  border: none;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  outline: none;
  color: #003663;
}

.arrow-link a span {
  font-size: 13px;
  font-weight: 400;
}

.arrow-link a img {
  position: absolute;
  bottom: 9px;
  right: 17px;
}

.room-spce > div {
  padding: 0px 5px;
}

.room-spce {
  margin-left: -5px;
  margin-right: -5px;
}

.border-blue {
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #003663;
  border-radius: 16px;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
}

.tableinfo {
  margin-right: -27px;
  margin-left: -27px;
  margin-right: auto;
  margin-left: auto;
}

.tableinfo .table thead th {
  padding: 10px 0px;
  border: none;
  border-bottom: 3px solid #003663;
  color: #003663;
  font-size: 19px;
  font-weight: 600;
}

.tableinfo .table td {
  padding: 10px 0px;
  border: none;
  color: #003663;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
  line-height: 1.3;
  box-shadow: 0px 3px 0px 0px rgb(0 0 0 / 30%);
}

.tableinfo .table td img {
  margin-bottom: 3px;
}

.tableinfo .table td:last-child {
  text-align: right;
}

.tableinfo .table td.pricetext {
  color: #003663;
  font-size: 21px;
  font-weight: 600;
}

.default-yellow-btn.select {
  padding: 0px 18px;
  font-weight: 500;
}

.spdiv {
  margin-left: -5px;
  margin-right: -5px;
}

.spdiv > div {
  padding-left: 3px;
  padding-right: 3px;
}

.sps {
  padding-left: 4px;
  padding-right: 4px;
}

.nav-arrows .owl-nav {
  margin: 0;
}

.img-carousel.owl-carousel .owl-nav button,
.nav-arrows.owl-carousel .owl-nav button {
  width: 26px;
  height: 26px;
  position: absolute;
  bottom: 11px;
  right: 5px;
  text-indent: -999px;
}

.img-carousel.owl-carousel .owl-nav button.owl-prev,
.nav-arrows.owl-carousel .owl-nav button.owl-prev {
  background: url(../img/slider-arrow-left.png) 50% 50% no-repeat;
  right: 40px;
}

.img-carousel.owl-carousel .owl-nav button.owl-next,
.nav-arrows.owl-carousel .owl-nav button.owl-next {
  background: url(../img/slider-arrow-right.png) 50% 50% no-repeat;
}

.nav-arrows.owl-carousel .owl-nav button.owl-prev {
  top: 50%;
  margin-top: -13px;
  left: 10px;
  right: auto;
}

.nav-arrows.owl-carousel .owl-nav button.owl-next {
  top: 50%;
  margin-top: -13px;
  right: 10px;
}

.incp img {
  margin-right: 10px;
}

.incr {
  color: #003663;
  font-size: 15px;
  float: right;
}

.incr {
  color: #003663;
  font-size: 15px;
}

.incr input {
  width: 15px;
  height: 25px;
  background: none;
  border: none;
  color: #003663;
  text-align: center;
  padding: 0px;
}

.incr button {
  width: 25px;
  height: 25px;
  border: 2px solid #acacac;
  padding: 0;
  border-radius: 50%;
  color: #003663;
}
.incr button :disabled{
  cursor: not-allowed;
}

.subhead {
  font-size: 15px;
  font-weight: 500;
}

.timing-radio {
}

.custom-control.timing-radio.custom-radio label {
  font-size: 9px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  padding: 10px 5px;
}

.custom-control.timing-radio.custom-radio label img {
  margin-right: 5px;
}

.custom-control.timing-radio.custom-radio {
  display: inline-block;
  width: 48%;
  vertical-align: top;
  margin-right: 1%;
  padding-left: 0px;
}

.filterbar .timing-radio .custom-control-label::before {
  color: #fff;
  border-color: #003663;
  background: none;
  border: 2px solid #c2c2c2;
  border-radius: 13px;
  width: 100%;
  height: 100%;
  left: 0;
  box-shadow: none !important;
  background: none;
}

.filterbar .timing-radio .custom-control-label::after {
  display: none !important;
}

.filterbar .timing-radio .custom-control-input {
  width: 100%;
  height: 100%;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background: none !important;
}

.filterbar
  .custom-radio.any-input
  .custom-control-input:checked
  ~ .custom-control-label::after {
  top: 6px;
  left: -18px;
  width: 10px;
  height: 10px;
  background: #003663;
  border-radius: 50%;
}

.valuetabs .nav-tabs {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
  padding: 10px 15px 0px 20px;
}

.valuetabs .nav-tabs li {
  margin: 0;
  margin-right: 26px;
}

.valuetabs .nav-tabs a {
  color: #003663;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  border-bottom: 3px solid #003663;
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
  padding-right: 40px;
  display: inline-block;
}

.valuetabs .nav-tabs a.active,
.valuetabs .nav-tabs a:hover {
  border-color: #003663;
  cursor: pointer;
}

.valuetabs .nav-tabs a span {
  font-size: 15px;
  font-weight: 400;
}

.valuetabs .tab-pane {
  padding-top: 50px;
}

.valuetabs .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #003663;
}

.img-noti {
  position: absolute;
  top: -5px;
  right: 4px;
}

.rinfo {
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 1px dashed #acacac;
}

.rinfo .price-text {
  color: #034c88;
  font-size: 23px;
  font-weight: 500;
  line-height: 0.9;
}

.rinfo span {
  color: #034c88;
  font-size: 10px;
  font-weight: 500;
}

.rinfo .price-text sup {
  font-size: 14px;
  font-weight: 700;
}

.rinfo .default-yellow-btn.select {
  min-width: 131px;
  line-height: 40px;
  font-size: 14px;
}

.normal-radio {
  z-index: 0;
}

.valuetabs .custom-control-label img {
  margin-left: 0;
}

.normal-radio .custom-control-label {
  color: #003663;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  line-height: 26px;
}

.normal-radio .custom-control-label::before {
  border: 2px solid #003663;
  width: 22px;
  height: 22px;
  top: 2px;
  background: none;
}

.custom-radio.normal-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  top: 8px;
  left: -18px;
  width: 10px;
  height: 10px;
  background: #003663;
  border-radius: 50%;
}

.valuetabs .tinfo ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.valuetabs .tinfo ul li.text {
  color: #003663;
  font-size: 17px;
  font-weight: 500;
}

.valuetabs .tinfo ul li {
  width: 33.33%;
}

.valuetabs .tinfo ul li.mid {
  padding: 0px 10px;
  color: #003663;
  font-size: 13px;
}

.valuetabs .tinfo ul li.mid hr {
  margin: 3px 0px;
}

.lugguge-info {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.lugguge-info li {
  display: inline-block;
  color: #003663;
  font-size: 13px;
  font-weight: 500;
  margin-right: 7px;
}

.lugguge-info li img {
  margin-right: 3px;
}

.lugguge-info li:last-child {
  margin-right: 0px;
}

.valuetabs .card {
  border: none;
  padding: 0;
  border-radius: 0px 0px 0px 4px;
  background: none;
}

.valuetabs .card-header {
  padding: 0;
  border: none;
}

.valuetabs .accordion {
  width: 100%;
}

.valuetabs .card-header .btn {
  color: #003663;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 15px 10px 30px;
  border-top: 1px dashed #acacac;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
}

.valuetabs .card-header .btn.collapsed {
  box-shadow: none;
}

.valuetabs .card-header .btn:hover,
.valuetabs .card-header .btn:focus {
  outline: none;
  text-decoration: none;
}

.valuetabs .card-header .btn img {
  margin-left: 10px;
}

.valuetabs .card-header .btn img {
  transform: rotate(180deg);
}

.valuetabs .card-header .btn.collapsed img {
  transform: rotate(0deg);
}

.valuetabs .card-header .btn img {
  display: inline-block;
}

.valuetabs .card-header .btn.collapsed span {
  display: none;
}

.bg-angles:before {
  content: "";
  background: url(../img/angle-left.png) top left no-repeat;
  width: 15px;
  height: 36px;
  position: absolute;
  top: 0px;
  left: -2px;
  z-index: 1;
}

.bg-angles:after {
  content: "";
  background: url(../img/angle-right.png) top right no-repeat;
  width: 16px;
  height: 36px;
  position: absolute;
  top: 0px;
  right: -2px;
  /* z-index: 1; */
}

.valuetabs .card-header .collapse.show {
}

.valuetabs .card-body {
  padding: 25px 30px;
}

.valuetabs .card-body h6 {
  font-size: 13px;
  font-weight: bold;
}

.valuetabs .card-body h6 span {
  font-size: 10px;
  font-weight: 400;
  display: inline-block;
  line-height: 1;
}

.deplocation {
  font-size: 13px;
  color: #003663;
  line-height: 1.1;
}

.deplocation span {
  font-size: 11px;
}

.filterbar
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before,
.filterbar
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  background-color: none;
  border-color: #003663;
}

.fs-10 {
  font-size: 10px;
  line-height: 1.1;
  display: inline-block;
}

hr.dashed {
  border-top: 1px dashed #acacac;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.tag-text {
  color: #003663;
  font-size: 13px;
}

.tagis {
  background: url(../img/bg-tag.png) bottom center no-repeat;
  width: 26px;
  height: 33px;
  display: inline-block;
  vertical-align: middle;
  color: #fefefe;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  padding-top: 10px;
  margin-right: 7px;
  vertical-align: baseline;
}

.slider-box .package-box {
  float: left;
}

.slider-box .package-box figure {
  height: 211px;
  border-radius: 7px 0px 0px 7px;
}

.package-box .tag {
  display: inline-block;
}

.package-box .tag.small {
  line-height: 21px;
  font-size: 13px;
  padding: 0 11px;
}

.slider-box .package-box .tag.small {
  top: 10px;
  z-index: 9;
}

.slider-box h5 {
  font-size: 19px;
  line-height: 1.2;
  margin-bottom: 2px;
}

.slider-box p {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}

.slider-box .price-text {
  color: #034c88;
  font-size: 23px;
  font-weight: 500;
  line-height: 0.9;
}

.slider-box .price-text sup {
  font-size: 14px;
  font-weight: 700;
}

.package-box .best-value-tag {
  top: -21px !important;
  border: none;
  left: 15px !important;
  min-width: 84px !important;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
}

.package-box .best-value-tag:before {
  display: none;
}

.info {
  background: #eeeeee;
  padding-bottom: 120px;
}

.payinfo {
  padding: 20px 30px;
  margin-top: -60px;
}

.payinfo h2 {
  color: #003663;
  font-size: 27px;
  font-weight: 600;
}

.payinfo p {
  color: #003663;
  font-size: 17px;
  font-weight: 500;
  list-height: 1.2;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: -6px;
  bottom: -3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
}

.switch input:checked + .slider {
  background-color: #003663;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #003663;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.info {
  background: #eeeeee;
  padding-bottom: 120px;
}

.info p.lg {
  color: #003663;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3;
}

.info p.lg span {
  color: #003663;
  font-weight: 600;
}

.info p.lg span span {
  font-weight: 500;
}

.info h4 {
  color: #003663;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 25px;
}

.info label {
  color: #003663;
  font-size: 17px;
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 2px;
}

.info .form-control {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
  height: 58px;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
}

.info select.form-control {
  background: #fff url(../img/down-arrow-blue.png) 96% 50% no-repeat;
  background-size: 15px auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.info p {
  color: #003663;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.3;
}

.info .custom-control.tick {
  border-bottom: 2px solid #003663;
}

.info .custom-control.normal {
  border: none;
}

.info .custom-control.normal .custom-control-label {
  font-size: 13px;
  line-height: 1.1;
}

.info .custom-control.normal .custom-control-label:before {
  top: 50%;
  margin-top: -13px;
}

.info .custom-control.normal .custom-control-label:after {
  top: 50% !important;
  margin-top: -7px;
}

.custom-control.tick {
  margin-bottom: 7px;
}

.custom-control.tick .custom-control-input {
  width: 22px;
  height: 22px;
}

.custom-control.tick .custom-control-label {
  color: #003663;
  font-weight: 500;
  font-size: 19px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  width: 100%;
}

.custom-control.tick .custom-control-label::before {
  border: 2px solid #003663;
  width: 22px;
  height: 22px;
  top: 0;
  background: none;
}

.custom-control.tick
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #003663;
  background: none;
}

.custom-control.tick
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background:  50% 50% no-repeat;
  border-radius: 0;
  top: 4px;
    left: -20px;
    width: 14px;
    height: 14px;
  background-image:    url(../img/64/Check-sign.png);
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;    
}
.css-1cxuydk-MuiPaper-root-MuiDrawer-paper{
  height: auto !important;
}

.custom-control.tick .custom-control-label img {
  margin-top: -15px;
}

.btn-submit {
}

.btn-submit span {
  font-size: 17px;
  font-weight: 600;
  margin-left: 5px;
}

.btn-submit span sup {
  font-size: 12px;
  font-weight: 600;
}

.iti--separate-dial-code .iti__selected-flag {
  background: none;
  border-right: 1px solid #cccccc;
  color: #003663;
  font-size: 17px;
  font-weight: 500;
}

.iti__arrow {
  background: url(../img/down-arrow-blue.png) 50% 50% no-repeat;
  background-size: 100% auto;
  width: 14px;
  height: 10px;
  border: none;
}

.iti__arrow:before,
.iti__arrow:after {
  display: none;
}

.imgbox {
  background: linear-gradient(rgba(0, 54, 99, 0.43), rgba(0, 54, 99, 0.43)),
    url(../img/small-img.png) no-repeat 50% 50% / cover;
  background-size: cover;
  border-radius: 5px 5px 0px 0px;
  padding: 18px 15px 15px 19px;
}

.imgbox h6 {
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.imgbox span {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
}

.whitebox.inner {
  padding: 5px 15px;
}

.whitebox.inner span {
  font-size: 10px;
  font-weight: 500;
  color: #003663;
}

.whitebox.inner p {
  font-size: 16px;
  font-weight: 500;
  color: #003663;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}

.space-tiny {
  padding: 0px 3px;
}

.total-text {
}

.total-text p span {
  font-size: 23px;
  font-weight: 600;
  float: right;
}

.total-text p span sup {
  font-size: 15px;
  font-weight: 600;
}

.baggageInfo {
  padding-bottom: 10px;
}

.baggageInfo td {
  border: none;
  color: #003663;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 0px;
}

.baggageInfo td:first-child {
  width: 35%;
}

.baggageInfo td:nth-child(2) {
  width: 40%;
}

.baggageInfo .btn-submit {
  font-size: 13px;
  padding: 0px 20px;
}

.baggageInfo .btn-submit span {
  font-size: 17px;
  margin-left: 2px;
}

.baggageInfo .btn-submit span sup {
  font-size: 12px;
}

.totaldiv .itemdetail {
  color: #034c88;
  font-size: 17px;
  font-weight: 500;
  display: block;
  padding-bottom: 5px;
}

.totaldiv .itemdetail span {
  float: right;
}

.totaldiv .total-price {
  color: #034c88;
}

.tripinfo {
  padding: 20px 20px 20px 30px;
}

.tripinfo .tinfo ul li {
  width: 33.33%;
}

.tripinfo .tinfo ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.tripinfo .tinfo ul li.text {
  color: #003663;
  font-size: 17px;
  font-weight: 500;
}

.tripinfo .tinfo ul li.mid {
  padding: 0px 10px;
  color: #003663;
  font-size: 13px;
}

.tripinfo .tinfo ul li.mid hr {
  margin: 3px 0px;
}

.dropdown-menu.bluedrop {
  background: #034c88;
  color: #fff;
  border: none;
  border-radius: 0px;
}

.dropdown-menu.bluedrop .dropdown-item {
  color: #fff;
  border: none;
  border-radius: 0px;
}

.dropdown-menu.bluedrop .dropdown-item:hover,
.dropdown-menu.bluedrop .dropdown-item.active {
  color: #034c88;
}

.pickup-dropdown ul,
.departure-dropdown ul,
.traveller-dropdown ul,
.pickup-dropdown2 ul,
.departure-dropdown2 ul,
.traveller-dropdown2 ul,
.pickup-dropdown3 ul,
.departure-dropdown3 ul,
.traveller-dropdown3 ul .pickup-dropdown4 ul,
.departure-dropdown4 ul,
.traveller-dropdown4 ul {
  border-radius: 10px;
  position: absolute;
  overflow: auto;
  width: 100%;
  z-index: 9999;
  margin-top: 10px;
  max-height: 300px;
  background: #fff;
  /*display:none;*/
}

.destination-dropdown ul {
  border-radius: 10px;
  position: absolute;
  overflow: auto;
  width: 100%;
  z-index: 9999;
  margin-top: 10px;
  max-height: 300px;
  background: #fff;
  height: auto !important;
  /*display:none;*/
}

.destination-dropdown ul li a, .destination-dropdown ul li button {
  border: 0px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  /* background: #fff url(../img/flight1.png) 10px 50% no-repeat; */
  background-size: auto;
  padding-left: 10px;
  text-align: left;
  color: #003663;
}

.pickup-dropdown ul,
.departure-dropdown ul,
.pickup-dropdown2 ul,
.departure-dropdown2 ul,
.traveller-dropdown2 ul,
.pickup-dropdown3 ul,
.departure-dropdown3 ul,
.traveller-dropdown3 ul .pickup-dropdown4 ul,
.departure-dropdown4 ul,
.traveller-dropdown4 ul {
  display: none;
}

.traveller-dropdown ul,
.traveller-dropdown2 ul,
.traveller-dropdown3 ul,
.traveller-dropdown4 ul {
  height: auto !important;
}

.pickup-dropdown ul li a,
.pickup-dropdown ul li button,
.departure-dropdown ul li a,
.traveller-dropdown ul li a,
.pickup-dropdown2 ul li a,
.departure-dropdown2 ul li a,
.traveller-dropdown2 ul li a,
.pickup-dropdown3 ul li a,
.departure-dropdown3 ul li a,
.traveller-dropdown3 ul li a,
.pickup-dropdown4 ul li a,
.departure-dropdown4 ul li a,
.traveller-dropdown4 ul li a {
  border: 0px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  background: #fff url(../img/flight1.png) 10px 50% no-repeat;
  background-size: auto;
  padding-left: 60px;
  color: #003663;
}

.departure-dropdown ul li a {
  background: #fff url(../img/flight2.png) 10px 50% no-repeat;
  background-size: auto;
}

.traveller-dropdown ul li a,
.traveller-dropdown2 ul li a,
.traveller-dropdown3 ul li a,
.traveller-dropdown4 ul li a {
  background: #fff url(../img/bg-user.png) 10px 50% no-repeat;
  background-size: auto;
}

.traveller-hearder {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.closeTravellerWindow {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 15px;
  padding-top: 15px;
  color: #005dba;
}

.pickup-dropdown ul li:hover a,
.departure-dropdown ul li:hover a,
.traveller-dropdown ul li:hover a,
.pickup-dropdown2 ul li:hover a,
.departure-dropdown2 ul li:hover a,
.traveller-dropdown2 ul li:hover a,
.pickup-dropdown3 ul li:hover a,
.departure-dropdown3 ul li:hover a,
.traveller-dropdown3 ul li:hover a,
.pickup-dropdown4 ul li:hover a,
.departure-dropdown4 ul li:hover a,
.traveller-dropdown4 ul li:hover a {
  background: rgba(34, 159, 218, 0.5) url(../img/flight1.png) 10px 50% no-repeat;
  background-size: auto;
}

.traveller-dropdown ul li:hover a,
.traveller-dropdown2 ul li:hover a,
.traveller-dropdown3 ul li:hover a,
.traveller-dropdown4 ul li:hover a {
  background: rgba(34, 159, 218, 0.5) url(../img/bg-user.png) 10px 50% no-repeat;
  background-size: auto;
}

.blurr {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(3, 76, 136, 0.8);
  top: 0px;
  left: 0px;
  z-index: 1;
  display: none;
}

.blurr2 {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(3, 76, 136, 0.8);
  top: 0px;
  left: 0px;
  z-index: 2;
}

.banner .bot-minus {
  z-index: 9;
}

.traveller-dropdown ul li.custom-radio,
.traveller-dropdown2 ul li.custom-radio,
.traveller-dropdown3 ul li.custom-radio,
.traveller-dropdown4 ul li.custom-radio {
  border: 0px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 15px;
  color: #003663;
}

.traveller-dropdown ul li.custom-radio input,
.traveller-dropdown2 ul li.custom-radio input,
.traveller-dropdown3 ul li.custom-radio input,
.traveller-dropdown4 ul li.custom-radio input {
  margin-right: 10px;
}

/**/
.departure-dropdown2 ul li a {
  background: #fff url(../img/bg-user.png) 10px 50% no-repeat;
  background-size: auto;
}

.departure-dropdown2 ul li:hover a {
  background: rgba(34, 159, 218, 0.5) url(../img/bg-user.png) 10px 50% no-repeat;
  background-size: auto;
}

/*** QUANTITY ***/
.quantity {
  display: flex;
  align-items: center;
  padding: 0;
}

.quantity button {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0;
  background: none;
  border: 2px solid #888;
  color: #888;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  vertical-align: middle;
  font-size: 17px;
  line-height: 20px;
}

.quantity input {
  width: 32px;
  height: 30px;
  margin: 0;
  padding: 0;
  border-top: 2px solid #dee0ee;
  border-bottom: 2px solid #dee0ee;
  border-left: 1px solid #dee0ee;
  border-right: 2px solid #dee0ee;
  background: none;
  color: #0d191b;
  font-size: 22px;
  font-weight: 300;
  border: none;
  text-align: center;
}

.quantity button:hover,
.quantity button.active {
  background: none;
  border-color: #003663;
  color: #003663;
}

.travel-drop input {
  background: #fff url(../img/icon-calendar.png) 5px 50% no-repeat;
  padding-left: 35px;
  font-size: 11px;
}

.travel-drop .headi {
  background: #ccc;
  color: #034c88;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
}

.travel-drop ul {
  list-style: none;
  display: inline-block;
  width: 100%;
  padding: 5px 0px;
  margin: 0;
}

.travel-drop ul li {
  list-style: none;
  display: inline-block;
  width: 48%;
  text-align: center;
}

.travel-drop ul li a {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
}

.drop-travellers {
  border: none;
  background: url(../img/bg-user.png) 0% 50% no-repeat,
    url(../img/down-arrow-blue.png) 100% 50% no-repeat;
  background-color: #fff;
  border: none;
  color: #003663;
  font-weight: 500;
  font-size: 20px;
  outline: none;
  width: 190px;
  padding-left: 30px;
  padding-right: 27px;
  outline: none;
  box-sizing: none;
  height: 31px;
  margin: 0px 4px;
}

.drop-travellers:focus,
.drop-travellers:hover,
.drop-travellers:active {
  outline: none;
  box-sizing: none;
}

.normal-drop .traveller-dropdown4 ul {
  min-width: 300px;
}

.drop-travellers:focus {
  outline: none;
}

.drop-travellers::-webkit-input-placeholder {
  color: #003663;
}

.normal-drop {
  display: inline-block;
  vertical-align: middle;
}

.exp-date .form-control {
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  text-align: center;
}

.drop-travellers.adults {
  width: 160px;
}

.banner .datebox .form-control.bg-calendar {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: inherit;
}

.banner .sprdiv > div > div {
  padding: 0;
}

.sprdiv.daterow {
  margin-left: 0px;
  margin-right: 0px;
}

.banner .multi-city .form-control {
  font-size: 18px;
}

.sprdiv .traveller-dropdown ul li.custom-radio {
  padding-left: 35px;
  margin-right: 0px;
  margin-left: 0px;
}

.sprdiv .traveller-dropdown .custom-control-label {
  line-height: 26px;
}

.traveller-dropdown ul li:last-child {
  border: 0px;
  margin-bottom: 0px;
}

.banner .datebox .to-date {
  border-left: 2px solid #003663;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  /* max-width: 265px; */
}

.sprdiv h4 {
  color: #fff;
  font-size: 17px;
}

.bg-white-box {
  background: #fff;
  margin: 0px 15px 20px 15px;
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
  height: 58px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
}

.bg-white-box .form-control {
  box-shadow: none;
  border-radius: 0px;
}

.bg-white-box .datepicker {
  border-right: 1px solid #032745;
}

.bg-white-box select.form-control {
  border-right: 1px solid #032745;
}

.bg-gray {
  background-color: #e9e9e9;
}

.bg-white {
  background-color: #ffffff;
}

.bradius-10 {
  border-radius: 10px;
}

.ticket-background {
  background: url(../img/ticket-background.png);
  min-height: 250px;
  min-width: 600px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.multiple-image-view {
  background-color: transparent;
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  place-content: space-between;
  -webkit-box-pack: justify;
}
.multiple-image-view > img {
  border-radius: 3px;
}

.text-ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

.react-google-flight-datepicker {
  border-radius: 10px;  
}
@media only screen and  (max-width: 990px) and (min-width: 767px) {
  .react-google-flight-datepicker .date-picker{
    padding: 0px !important;
  }
} 
.react-google-flight-datepicker .date-picker-input  {
  overflow: clip;
}

.react-google-flight-datepicker .date-picker-input svg {
  fill: #034c88;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  margin-left: .4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  background-color: gray;
}

.baggRadioCostum{
  display: flex;
    gap: 20px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.me-zbritje{
  font-size: large !important;
  text-decoration: line-through;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
  color: white;
  background: #034c88;
}
.inputHoverBB {
  
    background-color: red;
 } 
 .pointer{
  cursor: pointer;
 }
 .colorwhite{
  span {
    
  color:white !important;
}
color:white !important;
 }

 .onlyButton-kiwi{
  background-color: transparent;
  color: #003663;
  border: solid 1px #003663;
  border-radius: 5px;
 }

.react-google-flight-datepicker .day.selected.hovered::after {
  background-color: #034c88;
}

/* mySwiper */
.mySwiper {

}

.mySwiper img {
  width: 100%;
  height: 211px;
}

.mySwiper .swiper-button-prev {
  background: url(../img/slider-arrow-left.png) 50% 50% no-repeat;
}

.mySwiper .swiper-button-next {
  background: url(../img/slider-arrow-right.png) 50% 50% no-repeat;
}


.swiper-button-prev:after, .swiper-button-next:after {
  display: none;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 1 !important;
}

input, textarea {
  margin-bottom: 10px;
}

[data-feedback] {
  margin-bottom: 10px;  
}

[data-feedback].error {
  color: red;
}


.flight_ticket::before {
    /* font-family: Khand,sans-serif; */
    font-weight: 700;
    background-color: #fbd316;
    /* border-bottom: 4px solid #555555; */
    border-radius: 7px 7px 0px 0px;
    top: -23px;
    color: #003663;
    /* #555555; */
    content: attr(data-cheapest);
    font-size: 14px;
    left: 10px;
    padding: 2px 8px;
    position: absolute;
    text-transform: uppercase;
    z-index: -1;
}

.popular_list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.popular_list li {
  padding: 10px;
 
}

.popular_list .selected {
  color: #034c88;
  background-color: #eff7ff;
  font-size: 14px;
  font-weight: 500;
}

.react-google-flight-datepicker .dialog-header {
  background-color: rgb(0, 54, 99);
}
.react-google-flight-datepicker .back-button svg {
  fill: #fff;
}

.wpb_wrapper {
  box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    text-align: center;
    line-height: 1.73;
}

.st-our-team .title {
    font-family: Yellowtail;
    font-size: 48px;
    color: #ff7430;
    font-weight: 400;
}
.st-our-team .sub_title {
  margin-top: 3px;
  font-size: 48px;
  color: #333;
  font-family: Muli,sans-serif;
  margin-bottom: 46px;
  font-weight: 700;
}

.st-our-team .description {
  color: #7e7e7e;
    font-family: Muli,sans-serif;
    max-width: 876px;
    margin: auto;
    font-size: 18px;
    text-align: center;
    margin-bottom: 63px;
}

