@media only screen and (max-width: 991px) {
  .navbar {
    width: 100%;
  }

  .navbar-toggler {
    line-height: 1;
    padding: 0;
    border-radius: 0;
  }
  .navbar-toggler.menu-icon {
    width: 22px;
    padding: 0;
    cursor: pointer;
    position: relative;
    border: 0px;
  }
  .navbar-toggler.menu-icon span {
    width: 100%;
    height: 3px;
    display: block;
    background-color: #fff;
    margin: 6px 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .navbar-toggler.menu-icon.active span {
    position: absolute;
    top: calc(50% - 8px);
  }
  .navbar-toggler.menu-icon.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar-toggler.menu-icon.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar-toggler.menu-icon.active span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: none;
  }

  .navbar-light .navbar-brand {
    width: 75%;
  }

  .navbar-brand img {
    max-height: 40px;
  }

  .navbar-brand {
    margin-right: auto;
  }

  .navbar-nav {
    /* background: #000; */
    text-align: center;
    flex-direction: column !important;
  }

  .header {
    z-index: 99;
  }

  .banner {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .flush-xs {
    padding-right: 0px;
    padding-left: 0px;
  }

  .banner {
    background: #003663 !important;
  }

  .ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5;
    max-width: 92%;
    left: 15px !important;
  }

  .visible-xs {
    display: inline-block;
  }

  .xs-100 {
    width: 100%;
  }
  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  .underlined::after {
    left: 50%;
    margin-left: -35px;
    width: 70px;
  }

  .row-eq-height {
    display: inline-block;
  }

  .cta.secondary .card {
    margin-bottom: 30px;
  }

  .about .btn-default {
    margin-bottom: 30px;
  }

  .values h3,
  .newsletter h3 {
    text-align: center;
    width: 100%;
  }

  .payinfo {
    padding: 20px 20px;
    margin-top: -20px;
  }

  .payinfo h2 {
    font-size: 17px;
    margin-bottom: 20px !important;
  }

  .info p {
    font-size: 14px;
  }

  .info p.lg {
    font-size: 11px;
  }

  .iti {
    width: 100%;
  }

  .info .form-control {
    height: 50px;
    font-size: 14px;
    min-width: 200px;
  }

  .baggageInfo td {
    font-size: 12px;
  }

  .baggageInfo .btn-submit {
    font-size: 13px;
    padding: 0px 15px;
    min-width: 120px;
  }

  .baggageInfo .btn-submit span {
    font-size: 11px;
  }

  .baggageInfo .btn-submit span sup {
    font-size: 8px;
  }

  .custom-control.tick .custom-control-label {
    font-size: 15px;
  }

  .info .custom-control.tick {
    min-height: 35px;
  }

  .smilemsg {
    padding: 60px 20px;
    margin: 25px 0px;
  }

  .filterbar .headi {
    font-size: 16px;
  }

  .backbtn {
    font-size: 14px;
    font-weight: 500;
    line-height: 38px;
    padding: 0px 21px;
    margin-top: 20px;
  }

  .large-iframe {
    height: 400px;
  }

  .smilemsg p {
    font-size: 13px;
  }

  .default-yellow-btn.small {
    padding: 0px 16px;
    font-size: 13px;
  }

  .valuetabs .tinfo ul {
    margin-top: 10px;
  }

  .valuetabs .tinfo ul li.text {
    font-size: 15px;
  }

  .valuetabs .card-body .row.pl-2 {
    padding-left: 0px;
    margin-bottom: 10px;
  }

  .footer h3 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .footer p {
    font-size: 13px;
    line-height: 1.1;
  }

  .copyrights h5 {
    font-size: 14px;
  }

  .tripinfo .tinfo ul li.text {
    color: #003663;
  }

  .leftlinks ul li a {
    font-size: 13px;
    padding: 0px 10px;
  }

  .linksbar {
    flex-wrap: wrap;
  }

  .dropdown.normal-drop .dropdown-toggle {
    font-size: 12px;
    margin-top: 0px;
  }

  .dropdown.normal-drop {
    padding: 0px 5px;
    margin-top: 15px;
  }

  .dropdown.normal-drop .dropdown-toggle img {
    margin: 0px 0px;
    max-height: 14px;
  }

  .dropdown.normal-drop .dropdown-toggle img:last-child {
    margin-left: 2px;
    max-height: 7px;
  }

  .section-title h2 {
    font-size: 17px;
  }

  .package-text p {
    font-size: 16px;
  }

  .banner .nav-tabs a {
    font-size: 15px;
    line-height: 75px;
    padding: 0px 15px;
  }

    .nav-tabs a:hover {
      cursor: pointer;
    }



  .banner .tab-pane {
    padding: 5px 13px;
  }

  .banner .custom-control-label {
    font-size: 13px;
    line-height: 2;
  }

  .banner .form-control,
  .banner .form-control.bg-calendar {
    height: 60px;
    padding-left: 50px;
    font-size: 15px;
    background-size: 23px auto !important;
  }

  .banner .btn-submit {
    font-size: 20px;
    line-height: 67px;
  }

  .sprdiv.daterow {
    margin-bottom: 10px;
  }

  .datebox > div {
    padding: 0;
  }

  .banner .datebox .form-control.bg-calendar {
    height: 60px;
    padding-left: 50px;
    font-size: 15px;
    background: url(../img/icon-calendar.png) 17px 50% no-repeat;
    background-size: 23px auto !important;
  }

  .banner .ftinput .form-group {
    /* margin-bottom: 0;
    border-bottom: 2px dashed #ccc;
    background: #fff;
    border-radius: 10px 10px 0px 0px; */
    margin-bottom: 0;
    border-bottom: 2px dashed #ccc;
    border: none;
    position: relative;
    background: #fff;
    border-radius: 10px 10px 0px 0px;
  }

  .banner .btn-submit img {
    max-height: 20px;
  }

  .navbar-brand img {
    max-height: 35px;
  }

  .banner .nav-tabs a img {
    margin-right: 8px;
    max-height: 23px;
  }

  .banner .form-control.bg-user {
    background-size: 18px auto !important;
  }

  .banner .ftinput .form-control {
    border-radius: 10px 10px 0px 0px;
    box-shadow: none;
  }

  .banner .ltinput .form-control {
    border-radius: 0px 0px 10px 10px;
  }

  .drop-travellers {
    font-size: 12px;
    margin-top: 15px;
    background-size: 15px;
    width: 121px;
    padding-left: 21px;
    padding-right: 16px;
  }

  .drop-travellers.adults {
    width: 104px;
  }

  .banner .custom-control {
    margin-left: 0px;
    margin-right: 0px;
  }

  .topbar .tel {
    display: none;
  }

  .p-class ul li>.list-group-item {
    border-bottom: 1px dashed #a5a4a4;
    padding-right: 0px;
    padding-left: 40px;
    background-size: 16px auto;
    padding-top: 16px;
    padding-bottom: 16px;
}

    .traveller-dropdown ul li:hover>.list-group-item {
        background-size: 16px auto;
    }

    .p-class ul li>.list-group-item .quantity {
        margin-top: -5px;
    }

    .p-class ul li>.list-group-item span.nme {
        color: #003663;
        line-height: 0.9;
        font-weight: 500;
        font-size: 16px;
    }

    .p-class ul li>.list-group-item span.nme span {
        color: #989ca0;
        font-size: 12px;
    }
    .p-class .quantity button:first-child {
      background:#ebebeb;
    }
    
    .p-class .quantity button {
      background: #fbd316;
      border-radius: 8px;
      border: none;
      width: 37px;
      height: 34px;
      font-weight: 400;
      line-height: 35px;
      box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
    }
    
    .p-class .quantity input {
      color: #003663;
      -webkit-text-fill-color:#003663;
      opacity: 1; /* required on iOS */
      width: 32px;
      height: 30px;
      font-size: 17px;
    }

    .p-class .list-group {
      max-height: 100%;
      position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100% !important;
    overflow-y: scroll;
    }

    .p-class ul h6 {
      background: #003663;
      color: #fff;
      padding: 29px 28px !important;
    }
    .p-class ul h6 .close-anchor {
      float: right;
      margin-top: -6px;
      display: inline-block!important;
    }

    .p-class ul li {
      padding: 0px 18px;
    }

    .p-class ul li.custom-control {
      margin-left: 18px !important;
      width: calc(100% - 36px);
      border: none !important;
      padding: 0px !important;
      margin-bottom: 10px;
    }

    .p-class ul li.custom-control input {
      border-radius: 0;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;
    }

    .p-class ul li.custom-control label {
      padding: 12px 25px;
    margin-right: 0px;
    margin-left: 0px;
    background: #ebebeb;
    width: 100%;
    box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
    border-radius: 8px;
    font-size: 15px;
    }

    .banner .custom-control {
      display: inline-block;
    vertical-align: middle;
    }
    .custom-control-input {
      opacity: 0;
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;
    }

    .radiobg .custom-radio .custom-control-input:checked~.custom-control-label::after {
      background: #0065a3;
      border-radius: 8px;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .radiobg .custom-radio .custom-control-input~.custom-control-label::before {
        left:0;
        display: none;
    }

    .sprdiv .traveller-dropdown .custom-control-label {
      line-height: 36px;
    }

    .traveller-dropdown ul li>.list-group-item {
      background: #fff url(../img/bg-user.png) 10px 50% no-repeat;
      border: 0;
    }

    .p-class ul li>.list-group-item {
      border-bottom: 1px dashed #a5a4a4;
      padding-right: 0px;
      padding-left: 40px;
      background-size: 16px auto;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .p-class ul li.custom-control.mt-sp {
      margin-top: 20px;
    }

    .p-class ul li.custom-control .custom-control-input~.custom-control-label::before {
      background: #003663;
      border-radius: 8px;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1 !important;
      content:'';
  }

  .custom-control-input:checked~.custom-control-label {
    /* background: #003663;
    color: #fff */
  }

  .p-class ul li.custom-control .custom-control-input~.custom-control-label::after {
    background:#003663 ;
  }
  .p-class ul li.custom-control .custom-control-label::after {
    display: none;
  }

  .p-class ul li.d-block.d-lg-none.d-md-none, li.d-block.d-lg-none.d-md-none {  
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 9;
    /* border-top: 1px solid #efefef; */
    background: #fff;
    /* box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%); */
  }

  .p-class ul li .btn-submit , li .btn-submit{
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 52px;
  
  }

 
  .p-class ul button, li .btn-submit { 
    background: #fbd316;
    color: #003663;
    font-weight: bold;
    /* font-size: 22px; */
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    /* line-height: 73px; */
    padding: 0px;
    box-shadow: 0px 5px 2px 0px rgba(0,0,0,0.4);
    
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  .payinfo h2 {
    font-size: 18px;
  }

  .info p {
    font-size: 13px;
  }

  .maplink {
    font-size: 15px;
  }

  .maplink img {
    max-width: 40px;
    margin-right: 5px;
  }

  .filterbar .headi {
    font-size: 14px;
  }
  .slider-box p {
    font-size: 14px;
  }
  .slider-box h5 {
    font-size: 15px;
  }

  .valuetabs .nav-tabs a {
    font-size: 18px;
    padding-bottom: 10px;
    padding-right: 15px;
  }

  .valuetabs .nav-tabs a span {
    font-size: 12px;
    font-weight: 400;
  }

  .valuetabs .nav-tabs li {
    margin: 0;
    margin-right: 10px;
  }

  .valuetabs .card-body .row.pl-2 {
    padding-left: 0px;
    margin-bottom: 10px;
  }

  .leftlinks ul li a {
    font-size: 13px;
    padding: 0px 10px;
  }

  .dropdown.normal-drop .dropdown-toggle {
    font-size: 14px;
  }

  .dropdown.normal-drop {
    padding: 0px 5px;
  }

  .dropdown.normal-drop .dropdown-toggle img {
    margin: 0px 3px;
    max-height: 18px;
  }

  .banner .form-control {
    height: 65px;
    padding-left: 55px;
    font-size: 16px;
  }

  .banner .btn-submit {
    font-size: 17px;
    height: 56px;
    line-height: 56px;
  }
  .btn-submit {
    font-size: 17px;
  }

  .banner .btn-submit img {
    max-width: 20px;
    margin-left: 5px;
  }

  .banner .form-control,
  .banner .form-control.bg-calendar {
    background-size: 19px auto !important;
    padding-left: 43px;
    font-size: 13px;
    height: 56px;
  }

  .drop-travellers {
    font-size: 14px;
    text-align: center;
    background-size: 15px auto, 19px auto;
    padding-left: 15px;
    width: 135px;
    padding-left: 19px;
    padding-right: 19px;
  }

  .drop-travellers.adults {
    width: 125px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .payinfo h2 {
  }

  .info p {
  }

  .whitebox.inner p {
    font-size: 13px;
  }

  .maplink {
    font-size: 15px;
  }

  .maplink img {
    max-width: 40px;
    margin-right: 5px;
  }

  .filterbar .headi {
    font-size: 14px;
  }

  .valuetabs .nav-tabs a {
    font-size: 18px;
    padding-bottom: 10px;
    padding-right: 15px;
  }

  .valuetabs .nav-tabs a span {
    font-size: 12px;
    font-weight: 400;
  }

  .valuetabs .nav-tabs li {
    margin: 0;
    margin-right: 20px;
  }

  .rinfo .default-yellow-btn.select {
    min-width: 105px;
    line-height: 34px;
    font-size: 14px;
    width: 100%;
  }

  .valuetabs .tinfo ul li.text {
    font-size: 14px;
    font-weight: 500;
  }

  .totaldiv .itemdetail {
    font-size: 13px;
  }

  .tripinfo .tinfo {
    max-width: 65%;
    flex: 0 0 65%;
  }

  .info p {
    font-size: 13px;
  }

  .total-text p span {
    font-size: 20px;
  }

  .total-text p span sup {
    font-size: 15px;
  }

  .package-text p {
    font-size: 18px;
  }

  .leftlinks ul li a {
    font-size: 13px;
    padding: 0px 10px;
  }

  .drop-travellers {
    font-size: 14px;
    text-align: center;
    background-size: 15px auto, 19px auto;
    padding-left: 15px;
    width: 135px;
    padding-left: 19px;
    padding-right: 19px;
  }

  .drop-travellers.adults {
    width: 125px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }

  .dropdown.normal-drop .dropdown-toggle {
    font-size: 14px;
  }

  .dropdown.normal-drop .dropdown-toggle img {
    margin: 0px 3px;
    max-height: 18px;
  }

  .banner .multi-city .form-control {
    font-size: 16px;
    padding-left: 55px;
  }

  .banner .form-control {
    font-size: 17px;
  }
}

@media screen and (max-width: 780px) {

      .valuetabs .nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        padding: 7px;
        gap: 20px;
      }

      .valuetabs .nav-tabs li {
        margin-right: 0;
      }

      .valuetabs .nav-tabs li a {
        margin-left: 5px;
        padding-right: 0;
        font-size: 17px;
        padding-bottom: 5px;
      }
      .valuetabs .nav-tabs li a span {
        font-size: 11px;
      }

      .bg-white-box {
        background: unset;
        margin: unset;
        box-shadow: unset;
        height: unset;
        /* padding-left: unset;
        padding-right: unset;
        border-radius:unset; */
      }

      .bg-white-box .form-control {
        box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
        border: none;
        border-radius: 10px;
      }
      .bg-white-box .datepicker {
        border-right: none
      }
      .bg-white-box select.form-control {
        border-right: unset;
      }
      .row {
        gap: 20px;
      }

      .row-no-gap {
        gap: 0 !important;
      }

      .destination-dropdown ul {
        max-width: 95%;
      }

      .banner .sprdiv > div > div {
        gap: 0;
        width: auto;
      }

      .banner .ftinput .form-group:before {
        content: '';
        width: calc(100% - 22px);
        height: 2px;
        border-bottom: 1px dashed #c2c2c2;
        position: absolute;
        bottom: 0;
        left: 11px;
        z-index: 1;
      }

      .banner {
        padding-top: 50px;
      }
      .banner  .row {
        gap: 0;
      }
      .banner .nav-tabs a {
        padding-top: 0 !important;
        margin: 0 !important;
      }

      .banner .bot-minus {
        padding-left: 0;
        padding-right: 0;
      }

      .banner .form-control, .banner .form-control.bg-calendar {
        height: 50px;
      }

      .banner .btn-submit {
        line-height: 50px;
        font-size: 16px;
      }

      .react-google-flight-datepicker {
        height: unset;
      }

      .rinfo span {
        font-size: 13px;
      }

      .btn-submit {
        padding: 10px 40px;
        font-size: 16px;
      }

      .lugguge-info li:last-child {
        margin-top: 5px;
        margin-left: 3px;
      }
    
}

/* @media only screen and (min-width: 769px) and (max-width: 1200px) {
  .react-google-flight-datepicker .date-picker-input {
    display: block;
  }
  .react-google-flight-datepicker .date-picker {
    height: 56px;
    padding: 0;
  }
} */

.radiobg {
  box-shadow: 0px 5px 2px 0px rgb(0 0 0 / 40%);
  background-color: #fff;
  border-radius: 10px;
  margin: 10px 0px 15px 0px;
  float:left;
  width: 100%;
}
.radiobg .custom-control {
  margin: 0px;
  width: 50%;
  float: left;
  padding: 0;
  text-align: center;
}
.radiobg .custom-radio .custom-control-input:checked + label {
  color: #fff;
}

.radiobg .custom-control-label {
  color: #003663;
  margin: 0px;
  padding: 0;
  width: 100%;
  font-weight: 600;
}